var slc = {};
/**
 *
 * Build Tools for Building DOM Elements
 *
 */

slc.build = {};

/**
 *
 * Helper method to do a replace all in a string using the provided regex
 *
 * @param {regex} regex The pattern to look for to replace
 * @param {String} replaceWith The string to put in the place of matching items
 * @param {String} str The string the regex pattern will search in
 * @returns {unresolved}
 */

slc.build._replaceAll = function(regex, replaceWith, str) {
	return str.replace(new RegExp(regex, 'g'), replaceWith);
};

/**
 *
 * Builds a element node with no attributes and some display text
 *
 * @param {String} elementType
 * @param {String} displayValue
 * @returns {Element}
 */
slc.build.elementWithText = function (elementType, displayValue) {
	var element = document.createElement(elementType);
	element.appendChild(document.createTextNode(displayValue));
	return element;
};

/**
 *
 * Generic function that will build an element with optional displayText
 * and add attributes to the element based on the map using the map.key
 * as the attribute name and the map.value as the attributes value
 *
 * @param {string} elementToMake
 * @param {Object} elementAttributes
 * @param {string} displayText
 * @returns {Element}
 * example: slc.build.elementWithAttributes('div', {class: "foo", id: "faa"}, 'bar');
 */
slc.build.elementWithAttributes = function(elementToMake, elementAttributes, displayText) {
	var element = document.createElement(elementToMake);

	for (var prop in elementAttributes) {
		element.setAttribute(prop, elementAttributes[prop]);
	}

	if (displayText || displayText === 0) {
		element.appendChild(document.createTextNode(displayText));
	}
	return element;
};


/**
 *
 * Generic function that will place an element within a specified container
 */
slc.build.place = function(element, container) {
	container = document.querySelector(container);
	container.appendChild(element);
};

slc.build.escapeChars = { lt: '<', gt: '>', quot: '"', apos: "'", amp: '&' };

slc.build.unescapeHTML = function(str) {//modified from underscore.string and string.js
    return str.replace(/\&([^;]+);/g, function(entity, entityCode) {
        var match;

        if ( entityCode in slc.build.escapeChars) {
            return slc.build.escapeChars[entityCode];
        } else if ( match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
            return String.fromCharCode(parseInt(match[1], 16));
        } else if ( match = entityCode.match(/^#(\d+)$/)) {
            return String.fromCharCode(~~match[1]);
        } else {
            return entity;
        }
    });
};


/**
 *
 * Generic function that will wrap and element with an element
 *
 * @param {Object} element
 * @param {string} container
 * example: slc.build.wrap(document.querySelector('.wrap-me'), document.createElement('div'), 'wrapper');
 */

slc.build.wrap = function(element, wrapper) {
	element.parentNode.insertBefore(wrapper, element);
	wrapper.appendChild(element);
};
/**
 *
 * AJAX Tools
 * https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest
 * https://developer.mozilla.org/en-US/docs/AJAX/Getting_Started
 *
 */

slc.ajax = {};

/**
 *
 * slc.ajax.get
 * @param {string} url : path to endpoint
 * @param {object} params : params to send in URL
 * @callback {function} callback to run when readyState == 4
 *
 */

slc.ajax.get = function(url, params, callback) {
	// Send Params to String
	var str = '';
	if (typeof params == 'object') {
		// loop through objects/keys
		for (var key in params) {
			if (str != '') {
				str += '&';
			}
			str += key + '=' + encodeURIComponent(params[key]);
		}
	};
	url = url.concat('?', str);
	// start xmlhttprequest 
 	var xhr = new XMLHttpRequest();
	// xhr.open(method, url, async, user, password)
	xhr.open('GET', url, true);
	xhr.responseType = 'json';
	
	// New method: eventListener
	xhr.addEventListener('load', function(){
		if (xhr.readyState == 4) {
			if (typeof callback == 'function') {
				callback.apply(xhr);
			}
		}
	});
	// check on ready state for callback
	// xhr.onreadystatechange = returnCallback();
	// xhr.onreadystatechange = function() {
	// 	if (xhr.readyState == 4) {
	// 		// defensive check
	// 		if (typeof callback == "function") {
	// 			callback.apply(xhr);
	// 		}	
	// 	}
	// };
	xhr.send();
};

/**
 *
 * slc.ajax.post
  * @param {string} url : path to endpoint
 * @param {object} params : params to send in URL
 * @callback {function} callback to run when readyState == 4
 *
 */

slc.ajax.post = function(url, params, callback) {
	params = JSON.stringify(params);

	// start xmlhttprequest 
 	var xhr = new XMLHttpRequest();
	// xhr.open(method, url, async, user, password)
	xhr.open('POST', url, true);
	xhr.responseType = 'json';

	// headers
	xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
	xhr.setRequestHeader('Content-length', params.length);
	xhr.setRequestHeader('Connection', 'close');

	xhr.addEventListener('load', function(){
		if (xhr.readyState == 4) {
			if (typeof callback == 'function') {
				callback.apply(xhr);
			}
		}
	});

	xhr.send(params);

}
// ====================================================
// GLOBAL SELECTORS
// ====================================================
slc.globalheader = document.querySelector('header');
slc.globalnav = document.querySelector('header nav');
slc.cityscape = document.querySelector('section.cityscape');

// ====================================================
// IOS SAFARI CHECK
// ====================================================
slc.iosCheck = function() {
	var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
	var isIphone = /(iPhone)/i.test(navigator.userAgent);
	var isIpad = /(iPad)/i.test(navigator.userAgent);

	if (isSafari && (isIphone || isIpad)) {
		slc.cityscape.classList.add('ios');
	}
}();

// ====================================================
// SCROLLING HEADER
// ====================================================
slc.hdr = {};
slc.hdr.container = slc.globalheader;
slc.hdr.height = slc.hdr.container.scrollHeight;

slc.hdr.scrollcheck = function(e) {
	// console.log(e);
	var heightcheck = (slc.hdr.height * 6);
	if (e.pageY >= heightcheck) {
		slc.hdr.container.classList.add('scroll');
	} else {
		slc.hdr.container.classList.remove('scroll');
	}
};

document.addEventListener('scroll', slc.hdr.scrollcheck, false);


// ====================================================
// NAV FLY
// ====================================================

slc.navfly = {};
slc.navfly.container = document.querySelector('nav.fly');
slc.navfly.btn = {};
slc.navfly.btn.open = document.querySelector('button.globalmenu--open');
slc.navfly.btn.close = document.querySelector('button.globalmenu--close');

if (slc.navfly.container != null) {

	slc.navfly.open = function(action) {
		slc.navfly.container.classList.remove('closed');
	};

	slc.navfly.close = function(action) {
		slc.navfly.container.classList.add('closed');
	};


	// NAV FLY LISTNERS
	slc.navfly.btn.open.addEventListener('click', slc.navfly.open, false);
	slc.navfly.btn.close.addEventListener('click', slc.navfly.close, false);

	// Check for click outside of container when open
	document.addEventListener('click', function(e) {
		if (!slc.navfly.container.classList.contains('closed') && e.clientX > slc.navfly.container.clientWidth) {
			e.preventDefault();
			slc.navfly.close();
		}
	}, false);
	// Check for ESC while open
	document.addEventListener('keyup', function(e) {
		if (!slc.navfly.container.classList.contains('closed') && e.keyCode == 27) {
			slc.navfly.close();
		}
	}, false);

	// NAV FLY INIT --- Now closed by default
	// slc.navfly.close();
};

// ====================================================
// DIVISION NAV
// ====================================================

slc.dnav = {};
slc.dnav.container = document.querySelector('section.divisionsubnav nav');
slc.dnav.btn = {};
slc.dnav.btn.open = document.querySelector('button.divisionmenu--open');

if (slc.dnav.container != null) {
	
	// slc.dnav.btn.open.classList.toggle('active');

	slc.dnav.toggle = function(action) {
		slc.dnav.container.classList.toggle('closed');
		slc.dnav.btn.open.classList.toggle('active');
	};

	// NAV FLY LISTNERS
	slc.dnav.btn.open.addEventListener('click', slc.dnav.toggle, false);	
	// NAV FLY INIT
	// slc.dnav.toggle();
	// setTimeout(slc.dnav.toggle, 1000);

};



// ====================================================
// SEARCH ACTIONS
// ====================================================


slc.srch = {}
slc.srch.searchbox = document.querySelector('form.searchbox');
slc.srch.searchinput = document.querySelector('form.searchbox input[type="search"]');
slc.srch.btn = {}
slc.srch.btn.open = document.querySelector('button.searchbox--open');
slc.srch.btn.close = document.querySelector('button.searchbox--close');

if (slc.srch.searchbox != null) {

	slc.srch.open = function(action) {
		slc.srch.searchbox.classList.remove('closed');
		slc.srch.btn.open.classList.add('closed');
		slc.srch.btn.close.classList.remove('closed');
		slc.globalnav.classList.add('search--active');
		slc.srch.searchinput.focus();
	};

	slc.srch.close = function(action) {
		slc.srch.searchbox.classList.add('closed');
		slc.srch.btn.open.classList.remove('closed');
		slc.srch.btn.close.classList.add('closed');
		setTimeout(function () {
			slc.globalnav.classList.remove('search--active');
		}, 300);		
	};

	// SEARCH LISTENERS
	slc.srch.btn.open.addEventListener('click', slc.srch.open, false);
	slc.srch.btn.close.addEventListener('click', slc.srch.close, false);

	// SEARCH INIT
	slc.srch.close();

};



// ====================================================
// ACCORDION
// ====================================================

slc.accordion = {};

slc.accordion.containers = document.querySelectorAll('.accordion');

slc.accordion.init = function() {
	for (var i = 0; i < slc.accordion.containers.length; i++) {
		var accTitle = slc.accordion.containers[i].children[0];
		accTitle.addEventListener('click', slc.accordion.toggle, false);
	}
};

slc.accordion.toggle = function(e) {
	var container = e.currentTarget.parentElement;
	var view = container.dataset.view;

	if (view === 'closed') {
		container.dataset.view = 'open';
	}
	else if (view === 'open') {
		container.dataset.view = 'closed';	
	}
};

if (slc.accordion.containers.length >= 1) slc.accordion.init();


// ====================================================
// GALLERY WIDGET MODIFIER
// ====================================================

slc.gallery = {};

slc.gallery.containers = document.querySelectorAll('.widget .gallery');

slc.gallery.init = function() {
	for(var i = 0, length1 = slc.gallery.containers.length; i < length1; i++){
		slc.gallery.setBgs(slc.gallery.containers[i]);
	}
};

slc.gallery.setBgs = function(gallery) {
	var items = gallery.querySelectorAll('.gallery-item');
	
	for(var i = 0, length1 = items.length; i < length1; i++){
		var item = items[i];
		console.log(item);
		var img = item.querySelector('img');
		var imgsrc = img.src;
		var icon = item.querySelector('.gallery-icon');
		var caption  = item.querySelector('.gallery-caption');
		var href = item.querySelector('a');;
		img.style.display = 'none';
		icon.style.backgroundImage = 'url(' + imgsrc + ')';
		slc.gallery.setHref(caption, href);
	}

};

slc.gallery.setHref = function(el, url) {
	if (el) {
		el.classList.add('caption-haslink');
		el.addEventListener('click', function(){
			window.location = url;
		}, false);
	}
};

if (slc.gallery.containers.length >= 1) {
	slc.gallery.init();	
}

// ====================================================
// Multisite Search
// ====================================================

slc.search = {};
slc.search.url = '/restfeeds/';
slc.search.container = document.querySelector('article.globalsearch');
slc.search.info = document.querySelectorAll('.searchInfo');
slc.search.filters = document.querySelector('article.globalsearchFilters');
slc.search.filterCheckboxes = document.querySelectorAll('article.globalsearchFilters input');
slc.search.LoadMorebtn = document.querySelector('.search-loadmore .loadmore');
slc.search.types = [];
slc.search.offset = 0;


slc.search.init = function() {
	
	// Filters
	if (slc.search.filters) {
		for (var i = 0, length1 = slc.search.filterCheckboxes.length; i < length1; i++) {
			slc.search.filterCheckboxes[i].addEventListener('change', slc.search.filterUpdate, false);
		}
	}
	// Load More Listener
	slc.search.LoadMorebtn.addEventListener('click', slc.search.loadMore, false);

	slc.search.filterUpdate();
};


slc.search.filterUpdate = function(e) {
	slc.search.types = [];
	slc.search.offset = 0;

	for (var i = 0, length1 = slc.search.filterCheckboxes.length; i < length1; i++){
		if (slc.search.filterCheckboxes[i].checked) {
			slc.search.types.push(slc.search.filterCheckboxes[i].name);
			slc.search.filterCheckboxes[i].parentElement.classList.add('checked');
		}
		else {
			slc.search.filterCheckboxes[i].parentElement.classList.remove('checked');	
		}
	}

	slc.search.setupQuery();
};

slc.search.setupQuery = function() {
	var keyword = slc.search.container.dataset.searchterm;
	
	slc.search.siteSearch(keyword, slc.search.types);
};

slc.search.siteSearch = function(keyword, type) {

	if (type == undefined || type.length == 0) {
		type = ['page'];
	}

	var resultsType = '';
	// Build content string
	for (var i = 0, length1 = type.length; i < length1; i++) {
		resultsType += type[i];
		if ((i+1) < length1) resultsType += ',';
	}

	slc.ajax.get('/', {s: keyword, results: resultsType, offset: slc.search.offset, type: 'json'}, function() {
		if (this.readyState == 4 && this.response != null) {
			slc.search.buildresults(this.response);
		}
	});

};

slc.search.buildresults = function(res) {
	
	// IE FIX
	if (typeof res == 'string') res = JSON.parse(res);

	var data = res.data;
	var meta = res.meta;

	// Update Search Info
	slc.search.buildInfo(meta);	

	// Clear Existing
	if (slc.search.offset == 0 || slc.search.offset == undefined) slc.search.container.innerHTML = '';

	for (var i = 0, length1 = data.length; i < length1; i++) {

		var entry = slc.build.elementWithAttributes('a', {class: "result", 'data-type': data[i].type, href: data[i].url}, null);
		var title = slc.build.elementWithAttributes('h4', {}, slc.build.unescapeHTML(data[i].title));
			entry.appendChild(title);
		var sitemeta = slc.build.elementWithAttributes('div', {class: "sitemeta"}, data[i].site.name);
			entry.appendChild(sitemeta);
		var posted = slc.build.elementWithAttributes('div', {class: "meta"}, 'Posted: ' + data[i].meta);
			entry.appendChild(posted);
		if (data[i].desc) {
			var desc = slc.build.elementWithAttributes('div', {class: "entry"}, slc.build.unescapeHTML(data[i].desc));
				entry.appendChild(desc);	
		}
		var urlmeta = slc.build.elementWithAttributes('div', {class: "urlmeta"}, data[i].url);
			entry.appendChild(urlmeta);
		
		slc.search.container.appendChild(entry);
	}
	
	if (meta.foundPosts == 0) slc.search.container.innerText = 'No Results Found.';

	// Decide if there are more posts to show
	if ((parseInt(meta.postCount) + parseInt(slc.search.offset)) >= meta.foundPosts) {
		slc.search.loadMoreBtnActions('hide');
	}
	else {
		slc.search.loadMoreBtnActions('show');
	}
};

slc.search.buildInfo = function(meta) {
	if (meta.foundPosts == 0) {
		for (var i = 0, length1 = slc.search.info.length; i < length1; i++){
			slc.search.info[i].innerText = '';
		}
	}
	else {
		for (var i = 0, length1 = slc.search.info.length; i < length1; i++){
			slc.search.info[i].innerText = 'Showing ' + (meta.postCount + slc.search.offset) + ' of ' + meta.foundPosts + ' results';
		}
	}
	
};

slc.search.loadMore = function(e) {
	slc.search.offset = slc.search.offset + 10;
	slc.search.setupQuery();
};

slc.search.loadMoreBtnActions = function(action) {
	if (action == 'hide') slc.search.LoadMorebtn.classList.add('hidden');
	if (action == 'show') slc.search.LoadMorebtn.classList.remove('hidden');
};

if (slc.search.container) {
	slc.search.init();
}
/* ##################################################################
	MODALS
################################################################## */

slc.modal = {};

/**
 *
 * Modal Elements
 * 
 */

var closeBtns = document.querySelectorAll('button.modal-close');

/**
 *
 * Init Modals
 * 
 */

slc.modal.init = function() {

	// Destroy Listener
	if (closeBtns.length >= 1) {
		for (var i = 0, length1 = closeBtns.length; i < length1; i++) {
			closeBtns[i].addEventListener('click', slc.modal.destroy, false);
		}
	}
};

/**
 *
 * Destroy All Modals and Overlays
 * 
 */

slc.modal.destroy = function() {
	var modals = document.querySelectorAll('.modal');
	var overlays = document.querySelectorAll('.modal-overlay');
	for (var i = 0; i < modals.length; i++) {
		modals[i].parentNode.removeChild(modals[i]);
	}
	for (var i = 0; i < overlays.length; i++) {
		overlays[i].parentNode.removeChild(overlays[i]);
	}
};

slc.modal.init();
/* ##################################################################
	NOTICES EXTRA METHODS
################################################################## */

slc.notice = {};

/**
 *
 * Notification Elements
 * 
 */

slc.notice.closeBtns = document.querySelectorAll('button.banner-close, button.modal-close');

slc.notice.current = document.querySelector('.slc-notice');
slc.notice.currentOverlay = document.querySelector('.modal-overlay');

/**
 *
 * Init Notification Listeners / Etc
 * 
 */

slc.notice.init = function() {
	console.log('notice init');
	// Check for dismissals
	if (slc.notice.current && !slc.notice.checkDismissal()) {
		console.log('banner has NOT been dismissed');
		slc.notice.current.classList.remove('hidden');

		if (slc.notice.currentOverlay) slc.notice.currentOverlay.classList.remove('hidden');
	}

	// Dismiss Listener
	if (slc.notice.closeBtns.length >= 1) {
		for (var i = 0, length1 = slc.notice.closeBtns.length; i < length1; i++) {
			slc.notice.closeBtns[i].addEventListener('click', slc.notice.dismiss, false);
		}
	}
};

/**
 *
 * Check for Dismissed Banners
 * 
 */

slc.notice.checkDismissal = function() {
	
	// check the id for an existing dismissal
	var currentId = 'notice_' + slc.notice.current.dataset.noticeid;
	var dismissedId = sessionStorage.getItem(currentId);

	if (dismissedId == 'dismissed') {
		return true;
	}
	else {
		return false;
	}

};

/**
 *
 * Dismissals
 * 
 */

slc.notice.dismiss = function(e) {
	// Add dismissed to sessionStorage
	if (slc.notice.current) {
		var noticeId = 'notice_' + slc.notice.current.dataset.noticeid;
		sessionStorage.setItem(noticeId, 'dismissed');
	}
};

slc.notice.init();
var cal = {};
	cal.eventsurl = '/calendar/restfeeds/';
	cal.containers = document.querySelectorAll('.calevents');

cal.init = function() {

	for (var i = 0; i < cal.containers.length; i++) {
		var args = {};
			args.container = cal.containers[i];
			args.eventhtml = '';
			args.calargs = {};
			args.calargs.type = 'events';
			if (cal.containers[i].dataset.category) args.calargs.category = cal.containers[i].dataset.category;
			if (cal.containers[i].dataset.categoryname) args.calargs.categoryname = cal.containers[i].dataset.categoryname;
			if (cal.containers[i].dataset.department) args.calargs.department = cal.containers[i].dataset.department;
			if (cal.containers[i].dataset.departmentname) args.calargs.departmentname = cal.containers[i].dataset.departmentname;
			if (cal.containers[i].dataset.month) args.calargs.month = cal.containers[i].dataset.month;
			args.calargs.limit = cal.containers[i].dataset.limit;
		// Show Loadmore if Avail
		cal.loadmore.show(args.container, 'more');
		// Show Lazy Load
		cal.showloading(args);
	} // for

};



cal.showloading = function(args) {
	var loadingitems = 1;
	var loadinghtml = '<a class="cal-item loading"><div class="cal-meta"><span class="cal-month"></span><span class="cal-day"></span></div><div class="cal-entry"><h3 class="cal-title"></h3><p class="cal-desc"></p><p class="cal-location"></p><p class="cal-time"></p></div></a>';
	for (var i = 0; i < loadingitems; i++) {
		args.container.insertAdjacentHTML('beforeend', loadinghtml);
	}
	cal.get(args);
};

cal.noneavailable = function(args) {
	var loadinghtml = '<div class="cal-noevents">No events currently available</div>';
	args.container.innerHTML = '';
	args.container.insertAdjacentHTML('beforeend', loadinghtml);

	cal.loadmore.hide(args.container, 'more');
};

cal.get = function(args) {

	slc.ajax.get(cal.eventsurl, args.calargs, function() {
		if (this.response != null) {
			cal.parse(this.response, args);
		}
	});
};

cal.convertDate = function(date) {
	//convert from yymmdd to Month dd yy
	var monthNames = ['','January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	var datesplit = date.split('-');
	var month = datesplit[1].replace(/^0+/, '');
	date = monthNames[month] + ' ' + datesplit[2] + ', ' + datesplit[0];
	return date;
};

cal.parseDateTime = function(date) {
	var monthNames = ['','January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		pdate = new Date(date),
		pmonth = monthNames[pdate.getMonth() + 1],
		pday = pdate.getDate(),
		pyear = pdate.getFullYear(),
		phours = pdate.getHours(),
		pminutes = pdate.getMinutes(),
		ampm = phours >= 12 ? 'pm' : 'am',
		phours = phours % 12,
		phours = phours ? phours : 12,
		pminutes = pminutes < 10 ? '0'+pminutes : pminutes,
		ptime = phours + ':' + pminutes + ' ' + ampm;
	return (pmonth + ' ' + pday + ', ' + pyear + ' @ ' + ptime);
};

cal.parseMonth = function(date) {
	var monthNames = ['','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		pdate = new Date(date),
		pmonth = monthNames[pdate.getMonth() + 1];
	return pmonth;
};

cal.parseDay = function(date) {
	var pdate = new Date(date),
		pday = pdate.getDate();
	return pday;
};

cal.parseTime = function(date) {
	var pdate = new Date(date),
		phours = pdate.getHours(),
		pminutes = pdate.getMinutes(),
		ampm = phours >= 12 ? 'pm' : 'am',
		phours = phours % 12,
		phours = phours ? phours : 12,
		pminutes = pminutes < 10 ? '0'+pminutes : pminutes,
		ptime = phours + ':' + pminutes + ' ' + ampm;
	return ptime;
};

cal.trimText = function(text, limit) {
	if (!limit) limit = 300;
	if (text.length >= limit) {
		var trimmedText = text.substring(0, limit);
		return trimmedText.concat(' [...]');
	} else {
		return text;
	}
	
}

cal.parse = function(data, args){
	var items = data;
	
	// IF IE11
	if (typeof items == 'string') {
		items = JSON.parse(items);
	}
	
	if (items.length != 0 && args.calargs.limit >= 1) {

		args.container.innerHTML = '';

		for (var i = 0; i < items.length; i++) {

			var link = items[i].url,
				title = items[i].title,
				desc = items[i].desc,
				eventlocation = items[i].location,
				date = items[i].date,
				dateicon = date.split(/\s+/),
				year = items[i].year;

			var nItem = slc.build.elementWithAttributes('a', {class: "cal-item", href: link}, null);
			var nItemEntry = slc.build.elementWithAttributes('div', {class: "cal-entry"}, null);
			var nItemTitle = slc.build.elementWithAttributes('h3', {class: "cal-title"}, slc.build.unescapeHTML(title));
			if (desc) var nItemDesc = slc.build.elementWithAttributes('p', {class: "cal-desc"}, slc.build.unescapeHTML(cal.trimText(desc)));
			if (eventlocation) {
				var nItemLocation = slc.build.elementWithAttributes('p', {class: "cal-location"}, null);
					nItemLocation.innerText = eventlocation;
			} 
			
			if (date) {
				nItemTime = slc.build.elementWithAttributes('p', {class: "cal-time"}, date);
				nItemMonth = slc.build.elementWithAttributes('span', {class: "cal-month"}, dateicon[0]);
				nItemDay = slc.build.elementWithAttributes('span', {class: "cal-day"}, dateicon[1]);
			}

			// Meta Container
			var nItemMeta = slc.build.elementWithAttributes('div', {class: "cal-meta"}, null);
			// Build Meta Month/Date
			nItemMeta.appendChild(nItemMonth);
			nItemMeta.appendChild(nItemDay);
			// ...
			nItem.appendChild(nItemMeta);
			// Append Entry Container
			nItem.appendChild(nItemEntry);
			// ...
			nItemEntry.appendChild(nItemTitle);
			if (desc) nItemEntry.appendChild(nItemDesc);
			if (eventlocation) nItemEntry.appendChild(nItemLocation);
			nItemEntry.appendChild(nItemTime);
			// Final Append
			args.container.appendChild(nItem);
		}

		if (items.length < args.calargs.limit) {
			cal.loadmore.hide(args.container, 'more');
		}

	} else {
		cal.noneavailable(args);
	}
};


/* ##################################################################
	LOAD MORE CALENDAR FUNCTIONS
################################################################## */

// LOAD MORE FUNCTIONS
cal.loadmore = {};
cal.loadmore.btn = document.querySelectorAll('.calevents-loadmore .loadmore');

cal.loadmore.init = function(e) {
	var targetBtn = e.currentTarget;
	var eventsTarget = targetBtn.parentNode.previousSibling;

	if (targetBtn.dataset.type == 'more') {
		var targetLimit = parseInt(eventsTarget.dataset.limit);
		if (!eventsTarget.dataset.defaultlimit) {
			eventsTarget.dataset.defaultlimit = targetLimit;
			eventsTarget.dataset.limit = (targetLimit+targetLimit);
		}
		else {
			var targetDefaultLimit = parseInt(eventsTarget.dataset.defaultlimit);
			eventsTarget.dataset.limit = (targetLimit+targetDefaultLimit);
		}
		cal.loadmore.show(targetBtn.parentNode, 'reset');
	}
	else if (targetBtn.dataset.type == 'reset') {
		eventsTarget.dataset.limit = parseInt(eventsTarget.dataset.defaultlimit);
		eventsTarget.parentNode.scrollIntoView({behavior: 'smooth'});
		cal.loadmore.hide(targetBtn.parentNode, 'reset');
	}
	
	cal.init();
};

cal.loadmore.hide = function(container, btn) {
	// requires target container arg
	container = container.parentNode;
	var targetBtn = container.querySelector('.calevents-loadmore [data-type="'+btn+'"]');
	if (targetBtn) {
		targetBtn.classList.add('hidden');	
	}
};

cal.loadmore.show = function(container, btn) {
	// requires target container arg
	container = container.parentNode;
	console.log(container);
	var targetBtn = container.querySelector('.calevents-loadmore [data-type="'+btn+'"]');
	if (targetBtn) {
		targetBtn.classList.remove('hidden');	
	}
};

if (cal.loadmore.btn.length > 0) {
	for (var i = 0; i < cal.loadmore.btn.length; i++) {
		cal.loadmore.btn[i].addEventListener('click', cal.loadmore.init, false);
	}
};



/* ##################################################################
	GET/SETUP CALENDAR CATEGORIES
################################################################## */

// Prolly Slopsie Joes

cal.cats = {};
cal.cats.url = '/calendar/restfeeds/';

cal.cats.init = function() {

	cal.cats.containers = document.querySelectorAll('.cal-cats');
	cal.cats.resetBtn = document.querySelector('.cal-reset');

	if (cal.cats.containers.length > 0) {

		for (var i = 0; i < cal.cats.containers.length; i++) {

			var args = {};
				args.container = cal.cats.containers[i];
				args.type = cal.cats.containers[i].dataset.type; // eventcats or eventdepts
				args.currentid = cal.cats.containers[i].dataset.current;

			cal.cats.get(args);
		} // for

		cal.cats.resetBtn.addEventListener('click', cal.cats.reset, false);
	}
};

cal.cats.reset = function(e) {
	var targetCalEvents = e.currentTarget.parentNode.nextSibling;

	
	if (targetCalEvents.dataset.defaultlimit != undefined) targetCalEvents.dataset.limit = targetCalEvents.dataset.defaultlimit;

	for (var i = 0, length1 = cal.cats.containers.length; i < length1; i++) {
		var catCont = cal.cats.containers[i];

		switch (catCont.dataset.type) {
			case 'eventdepts':
				targetCalEvents.dataset.department = catCont.dataset.current;
				catCont.value = catCont.dataset.current;
				break;
			case 'eventcats':
				targetCalEvents.dataset.category = catCont.dataset.current;
				catCont.value = catCont.dataset.current;
				break;
			case 'eventmonths':
				if (catCont.dataset.current == 'all') {
					targetCalEvents.dataset.month = '';
					catCont.value = '';
				}
				else {
					targetCalEvents.dataset.month = catCont.dataset.current;
					catCont.value = catCont.dataset.current;
				}
				break;
			default:
				break;
		}
	} // for

	cal.cats.resetBtn.classList.add('hidden');

	cal.init();
};


cal.cats.update = function(e) {

	var targetCalEvents = e.currentTarget.parentNode.nextSibling;

	if (e.currentTarget.dataset.type == 'eventmonths') {
		targetCalEvents.dataset.month = e.currentTarget.value;
	}
	else {
		targetCalEvents.dataset.category = e.currentTarget.value;	
	}
	cal.cats.resetBtn.classList.remove('hidden');
	cal.init();
};

cal.cats.get = function(args) {

	slc.ajax.get(cal.cats.url, {type: args.type}, function() {
		// console.log(this.response);
		if (this.readyState == 4 && this.status == 200 && this.response != null) {
			cal.cats.parse(this.response, args);
		}
	});

};

cal.cats.parse = function(data, args) {

	var items = data;

	// IF IE11
	if (typeof items == 'string') {
		items = JSON.parse(items);
	}

	if (items.length != 0) {

		// Clear any <options>
		args.container.innerHTML = '';

		var nOptionAll = slc.build.elementWithAttributes('option', {value: ''}, 'All');
		args.container.appendChild(nOptionAll);

		if (args.type === 'eventmonths') {

			for (var item in items) {
				var catname = items[item].monthfull + ' (' + items[item].year + ')';
				var catid = items[item].year + '-' + items[item].month; // !!?
				var nOption = slc.build.elementWithAttributes('option', {value: catid}, catname);

				if (args.currentid == catid) {
					nOption.setAttribute('selected', 'selected');
				}
				args.container.appendChild(nOption);
			}
		}
		else {

			for (var i = 0; i < items.length; i++) {

				var catname = items[i].cat_name,
					catid = items[i].term_id;


				var nOption = slc.build.elementWithAttributes('option', {value: catid}, catname);
				
				if (args.currentid == catid) {
					nOption.setAttribute('selected', 'selected');
				}

				// console.log(nOption);

				args.container.appendChild(nOption);

			} // for

		} // if type

		cal.cats.addListeners(args);

	} // if

};

cal.cats.addListeners = function(args) {
	args.container.addEventListener('change', cal.cats.update, false);
};


/* ##################################################################
	INIT IF AVAIL
################################################################## */

if (cal.containers.length > 0) {
	cal.cats.init();
	cal.init();
}

// ====================================================
// Tables
// ====================================================

slc.table = {};


/**
 *
 * WRAP TABLE FOR TABLE-SCROLL-*
 *
 */

slc.table.scrollers = document.querySelectorAll('table');

slc.table.addScroll = function() {
	
	for (var i = 0; i < slc.table.scrollers.length; i++) {
		var classes = 'table-scroll';
		if (slc.table.scrollers[i].classList.contains('horizontal')) classes += ' horizontal';
		if (slc.table.scrollers[i].classList.contains('vertical')) classes += ' vertical';
		var wrapper = slc.build.elementWithAttributes('div', {class: classes}, null);
		slc.build.wrap(slc.table.scrollers[i], wrapper);
	}

};

console.log(document.body.clientWidth);

if (slc.table.scrollers.length > 0 && document.body.clientWidth <= 1024) {
	slc.table.addScroll();
} 
// ====================================================
// Eggs :)
// ====================================================

// slc.egg = {};
// slc.egg.active = false;
// slc.egg.body = document.querySelector('body');


// if (slc.egg.body.classList.contains('home') && slc.egg.active) {

// 	slc.egg.kkeys = [];
// 	slc.egg.konami = '38,38,40,40,37,39,37,39,66,65';
// 	slc.egg.tripped = false;
// 	slc.egg.bg = document.querySelector('section.cityscape');

// 	slc.egg.contra = function() {
// 		slc.egg.bg.style.backgroundImage = 'url(/wp-content/themes/slc/images/backgrounds/slcontra.gif)';
// 		slc.egg.audio = new Audio('/wp-content/themes/slc/images/backgrounds/slcontra.mp3');
// 		slc.egg.audio.play();
// 	};

// 	slc.egg.body.addEventListener('keyup', function(e) {

// 		slc.egg.kkeys.push(e.keyCode);

// 		if (slc.egg.kkeys.toString().indexOf(slc.egg.konami) >= 0 && !slc.egg.tripped) {
// 			slc.egg.tripped = true;
// 			slc.egg.kkeys = [];
// 			slc.egg.contra();
// 		}
		
// 	}, false);

// }